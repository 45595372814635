export default function Footer() {
  return (
    <footer>
      <p>
        Copyright © 2023 <span className="highlight"> Tuan Le </span> version 2.4.1 . All rights reserved. This website was created using the latest web development technologies and designed to
        provide the best user experience. Our team is dedicated to delivering high-quality products and services to our valued customers. For any inquiries or feedback, please don't hesitate to
        contact us.
      </p>
    </footer>
  );
}
